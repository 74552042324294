import React from "react";
import styled from "styled-components";

import { graphql, useStaticQuery } from "gatsby";

import { Query } from "@graphql-types";
import { useStore } from "@state/store";
import { isNotNullOrUndefined } from "@util/assertions";
import {
  MAX_WIDTH,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from "@util/constants";
import { Container, GridContainer, MenuButtonGatsbyLink } from "@util/standard";
import { GatsbyImage } from "gatsby-plugin-image";
import { StyledImage } from "./featuredBlogPost";
import { ImageWrapper } from "./hero.styles";
import BlocksContent from "./sub/blockContent";

interface Props {
  content: any;
  noDownloadButtons?: boolean;
  showButtonInMobile?: boolean;
  h2FontSize?: string;
  h2LineHeight?: string;
  isCenterContent?: boolean | undefined;
}

const CtaWrapper = styled.div`
  display: flex;
  width: 60%;
  margin: 100px auto;
  max-width: ${MAX_WIDTH}px;
  gap: 30px;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 30px auto;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 30px auto;
    flex-direction: column;
  }
`;

const TextWrapper = styled.div<{
  h2FontSize?: string;
  h2LineHeight?: string;
  isCenterContent?: boolean;
}>`
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({ isCenterContent }) => !!isCenterContent && `align-items: center;`}
  ${({ isCenterContent }) => !!isCenterContent && `text-align: center;`}

  h2 {
    display: inline-block;
    margin-bottom: 20px;
    ${({ isCenterContent }) => isCenterContent && `text-align: center;`}
    color: #000;
    ${({ h2FontSize }) => h2FontSize && `font-size: ${h2FontSize};`};
    ${({ h2LineHeight }) => h2LineHeight && `line-height: ${h2LineHeight};`};
  }
  p {
    margin-bottom: 20px;
    ${({ isCenterContent }) => !!isCenterContent && `text-align: center;`}
  }

  p > a {
    display: inline;
    ${({ isCenterContent }) => !!isCenterContent && `text-align: center;`}
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    text-align: center;
    a {
      margin: 20px auto 0px auto;
    }
    h2 {
      margin: 20px auto;
    }
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 90%;
    h2 {
      padding-right: 5px;
      margin-bottom: 0px;
    }
    p {
      margin-bottom: 10px;
    }
    a {
      margin: 10px auto 0px auto;
    }
  }
`;

const DownloadButtonContainer = styled.a`
  width: 100%;
`;

const DownloadButton = styled(GatsbyImage)`
  width: 100%;
  cursor: pointer;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 175px;
    margin: auto;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
  }

  &:hover {
    transform: scale(1.05);
  }

  transition: all 0.2s;
`;

const CtaLink = styled("div")`
  width: 100%;
  display: flex;
  align-items: center;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    grid-column: 1 / span 2;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-column: 1 / span 2;
  }
`;

function Cta(props: Props) {
  const {
    content,
    noDownloadButtons,
    showButtonInMobile,
    isCenterContent,
  } = props;
  const { ctaImage, ctaText, ctaButton } = content;

  const { ipLookup } = useStore();

  const { sanityAppDownload } = useStaticQuery<Query>(graphql`
    {
      sanityAppDownload {
        downloadButtons {
          _key
          url
          buttonImage {
            asset {
              gatsbyImageData(layout: CONSTRAINED, width: 127, fit: CLIP)
            }
          }
        }
      }
    }
  `);

  const downloadButtons = sanityAppDownload?.downloadButtons?.filter(
    isNotNullOrUndefined
  );

  return (
    <CtaWrapper>
      {!!ctaImage?.asset && (
        <ImageWrapper noDownloadLinks noXMargin>
          <StyledImage data={ctaImage} width="100%" />
        </ImageWrapper>
      )}
      <TextWrapper isCenterContent={isCenterContent}>
        <BlocksContent blocks={ctaText._rawContent} />
        {(ipLookup.isIncludedCountry || ipLookup.failedFetch) &&
        !noDownloadButtons ? (
          <GridContainer
            gridTemplateColumns="repeat(3, 1fr)"
            margin="30px 0px 0px 0px"
            tabletRepeat={2}
            mobileRepeat={2}
            tabletMargin="30px auto 60px auto"
            mobileMargin="20px auto 30px auto"
            width="100%"
            maxWidth={500}
            rowGap={20}
            columnGap={30}
          >
            {downloadButtons?.map(button => {
              return (
                <DownloadButtonContainer href={button.url ?? ""}>
                  <DownloadButton
                    key={button._key}
                    image={button.buttonImage?.asset?.gatsbyImageData}
                    alt={
                      button.buttonImage?.asset?.url
                        ? button._key ?? "Download button"
                        : "Download Button"
                    }
                    loading="lazy"
                  />
                </DownloadButtonContainer>
              );
            })}

            {ctaButton?.linktext && (
              <CtaLink>
                <MenuButtonGatsbyLink
                  noTabletVisibility={!showButtonInMobile}
                  href={ctaButton?.url}
                  color="secondary"
                  height="40px"
                >
                  {ctaButton?.linktext}
                </MenuButtonGatsbyLink>
              </CtaLink>
            )}
          </GridContainer>
        ) : (
          <Container
            width="300px"
            mobileMargin="auto"
            tabletMargin="auto"
            mobilePadding="0px"
          >
            {ctaButton?.linktext && (
              <MenuButtonGatsbyLink
                noTabletVisibility={!showButtonInMobile}
                href={ctaButton?.url}
                color="secondary"
                mobileWidth="127px"
              >
                {ctaButton?.linktext}
              </MenuButtonGatsbyLink>
            )}
          </Container>
        )}
      </TextWrapper>
    </CtaWrapper>
  );
}

export default Cta;
