import { colors } from "@util/constants";
import React from "react";
import { useEffect, useRef, useState } from "react";

export interface ToggleSwitchProps {
  primaryLabel: string;
  secondaryLabel: string;
  onClick: () => void;
  small?: boolean | null;
}

export const ToggleSwitch = ({
  primaryLabel,
  secondaryLabel,
  onClick,
  small,
}: ToggleSwitchProps) => {
  const [isToggled, setIsToggled] = useState(false);
  const primaryTextRef = useRef<HTMLDivElement>(null);
  const secondaryTextRef = useRef<HTMLDivElement>(null);
  const handleRef = useRef<HTMLDivElement>(null);
  const [handleLabel, setHandleLabel] = useState(primaryLabel);

  useEffect(() => {
    if (!isToggled) {
      setTimeout(() => {
        setHandleLabel(primaryLabel);
      }, 200);
    } else {
      setTimeout(() => {
        setHandleLabel(secondaryLabel);
      }, 100);
    }
  }, [isToggled]);

  const handleClick = () => {
    setIsToggled(prevState => !prevState);
    onClick();
  };

  return (
    <>
      <div
        id="track"
        onClick={handleClick}
        style={{
          display: "flex",
          columnGap: !small ? "45px" : "30px",
          padding: !small ? "10px 30px" : "10px",
          border: `1px solid ${colors.lightBlue}`,
          borderRadius: "50px",
          position: "relative",
          cursor: "pointer",
          backgroundColor: "white",
          margin: "10px",
        }}
      >
        <span
          ref={primaryTextRef}
          style={{
            fontSize: !small ? "20px" : "17px",
            color: colors.lightBlue,
            fontWeight: "bolder",
          }}
        >
          {primaryLabel}
        </span>
        <span
          ref={secondaryTextRef}
          style={{
            fontSize: !small ? "20px" : "17px",
            color: colors.lightBlue,
            fontWeight: "bolder",
          }}
        >
          {secondaryLabel}
        </span>
        <span
          ref={handleRef}
          id="handle"
          style={{
            position: "absolute",
            cursor: "pointer",
            padding: !small ? "11px 30px" : "11px 10px",
            left: "-2px",
            top: "-1px",
            backgroundColor: "#EDF6FF",
            borderRadius: "50px",
            zIndex: "1",
            // Experimentation with easing transition of width size changes
            // width: !isToggled
            //   ? `${Math.ceil(primaryTextRef.current?.clientWidth ?? 0) + 21}px`
            //   : `${(secondaryTextRef.current?.clientWidth ?? 0) + 20}px`,
            transform: isToggled
              ? `translateX(${
                  (primaryTextRef.current?.clientWidth ?? 0) +
                  (!small ? 52 : 34)
                }px)`
              : "none",
            transition: "transform 0.5s ease, width 0.3s ease",
            fontSize: !small ? "20px" : "17px",
            color: colors.lightBlue,
            fontWeight: "bolder",
          }}
        >
          {handleLabel}
        </span>
      </div>
    </>
  );
};
