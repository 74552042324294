import React from "react";
import { PricingLists } from "@components-v3/PricingLists";
import { Maybe, SanityPlansSection } from "@graphql-types";
import { colors, MOBILE_BREAKPOINT } from "@util/constants";
import { ToggleSwitch } from "@components-v3/ToggleSwitch";
import { useSafeMediaQuery } from "@util/hooks";

export interface ToggleButtonProps {
  primaryLabel: string;
  secondaryLabel: string;
  onClick: () => void;
}
interface PlansSectionProps {
  plansSectionData: Maybe<SanityPlansSection> | undefined;
  isPrimary: boolean;
  handleToggle: () => void;
}

const PlansSection = ({
  plansSectionData,
  isPrimary,
  handleToggle,
}: PlansSectionProps) => {
  const isMobile = useSafeMediaQuery({ maxWidth: MOBILE_BREAKPOINT });
  return (
    <>
      <h2 style={{ textAlign: "center" }}>{plansSectionData?.title ?? ""}</h2>
      <p style={{ textAlign: "center" }}>
        {plansSectionData?.description ?? ""}
      </p>
      <ToggleSwitch
        primaryLabel={plansSectionData?.toggleSwitch?.primaryLabel ?? ""}
        secondaryLabel={plansSectionData?.toggleSwitch?.secondaryLabel ?? ""}
        onClick={handleToggle}
        small={isMobile}
      />
      <p style={{ color: colors.formTextGrey }}>
        Prices are in {plansSectionData?.currency ?? ""}
      </p>
      <PricingLists
        sanityPricingLists={plansSectionData?._rawPricingLists}
        isPrimary={isPrimary}
      />
    </>
  );
};

export { PlansSection };
