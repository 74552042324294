import { Maybe, SanityPricePlan } from "@graphql-types";
import {
  SMALL_LAPTOP_BREAKPOINT,
  TABLET_BREAKPOINT,
  colors,
} from "@util/constants";
import { useSafeMediaQuery } from "@util/hooks";
import { generateId } from "@util/text";
import React, { RefObject } from "react";
import { FaCheck } from "react-icons/fa";
import styled from "styled-components";
import { Button } from "./Button";
import { Container } from "@util/standard";
import { createButtonQueryParams } from "./utils/pricingUtils";

export const GridItem = styled("div")<{ gridColumn: number; gridRow: number }>`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  padding: 0 20px 10px 20px;
  grid-row: ${({ gridRow }) => `${gridRow}`};
  grid-column: ${({ gridColumn }) => `${gridColumn}`};
  box-shadow: 0 12px 12px 0px rgba(0, 0, 0, 0.25);
  background-color: white;
  @media (max-width: ${TABLET_BREAKPOINT}px) {
    grid-column: 1;
    grid-row: auto;
    spacing: 5px;
    ${({ gridColumn, gridRow }) =>
      gridColumn !== 1 && gridRow === 1 && "margin-top: 40px; "}
  }
`;

const Checkmark = styled(FaCheck)`
  width: 20px;
  height: 20px;
  color: ${colors.lightBlue};
  align-self: center;
`;

const StyledP = styled("p")`
  font-size: 15px;
  margin: 0;
`;

interface PricingListProps {
  pricingList: Maybe<SanityPricePlan>;
  column: number;
  isPink: boolean;
  cellCount: number;
  isPrimary: boolean;
  parentRef: RefObject<HTMLDivElement>;
}

const PricingButton = styled(Button)`
  @media screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
    padding: 6.5px;
  }
`;

const PricingList = ({
  pricingList,
  column,
  isPink,
  isPrimary,
}: PricingListProps) => {
  const isTablet = useSafeMediaQuery({ minWidth: TABLET_BREAKPOINT });

  if (!pricingList) return <p>This pricing list could not be retrieved!</p>;

  // Recommended by MDN
  // See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#mobile_tablet_or_desktop
  const isMobileBrowser =
    typeof window !== "undefined"
      ? navigator.userAgent.includes("Mobi")
      : false;

  const isDesktopActionsEnabled = !isMobileBrowser || isTablet;

  const annualPriceDescriptionList = pricingList.annualPriceDescriptionList?.map(
    (desc, i) => <StyledP key={i}>{desc}</StyledP>
  );

  const monthlyPriceDescriptionList = pricingList.monthlyPriceDescriptionList?.map(
    (desc, i) => <StyledP key={i}>{desc}</StyledP>
  );

  const billingInterval = isPrimary ? "year" : "month";
  const selfServeQueryParams = createButtonQueryParams(
    pricingList.subscriptionTier ?? "",
    billingInterval
  );
  const desktopUrl = pricingList.pricingButton?.isInternal
    ? `${pricingList.pricingButton.linkUrl}?${selfServeQueryParams}`
    : pricingList.pricingButton?.linkUrl;

  const mobileUrl = pricingList.pricingButton?.isMobileInternal
    ? `${pricingList.pricingButton.linkUrl}?${selfServeQueryParams}`
    : pricingList.pricingButton?.mobileLinkURL;

  return (
    <>
      {pricingList.isMostPopular ? (
        <GridItem
          gridColumn={column}
          gridRow={1}
          style={{
            position: "relative",
            width: "100%",
            backgroundColor: "#1736bf",
            padding: "15px 20px",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            display: "flex",
            justifyContent: "center",
            boxShadow: "none",
            top: "15px",
            //Multiple box shadows on top cell of card to fill out top border
          }}
        >
          <p
            style={{
              color: "white",
              margin: 0,
              lineHeight: "normal",
              textAlign: "center",
            }}
          >
            MOST POPULAR
          </p>
        </GridItem>
      ) : (
        <GridItem
          gridColumn={column}
          gridRow={1}
          style={{ visibility: "hidden" }}
        ></GridItem>
      )}
      <GridItem
        gridColumn={column}
        gridRow={2}
        style={{
          padding: "35px 20px 0 20px",
          borderTopLeftRadius: pricingList.isMostPopular ? "0px" : "20px",
          borderTopRightRadius: pricingList.isMostPopular ? "0px" : "20px",
          //Multiple box shadows on top cell of card to fill out top border
          boxShadow:
            "rgba(0, 0, 0, 0.25) -9px 0px 12px -15px, rgba(0, 0, 0, 0.25) 0px 14px 12px 0px, rgba(0, 0, 0, 0.25) 30px -24px 12px -30px, rgba(0, 0, 0, 0.25) -30px -28px 12px -34px",
        }}
      >
        <h3 style={{ padding: 0 }}>{pricingList.title?.toLocaleUpperCase()}</h3>
      </GridItem>
      <GridItem gridColumn={column} gridRow={3}>
        <p style={{ fontSize: "12pt", margin: 0 }}>{pricingList.description}</p>
      </GridItem>
      <GridItem
        gridColumn={column}
        gridRow={4}
        style={{
          paddingTop: 0,
          paddingInline: "20px",
        }}
      >
        {!pricingList.isCustomPricing ? (
          <p
            style={{
              fontSize: "38px",
              margin: 0,
              lineHeight: "normal",
              letterSpacing: "-2px",
            }}
          >
            {isPrimary
              ? pricingList.annualPriceDescription
              : pricingList.monthlyPriceDescription}
          </p>
        ) : (
          <p style={{ fontSize: "12pt", margin: 0 }}>
            Contact sales for pricing.
          </p>
        )}
      </GridItem>
      <GridItem gridColumn={column} gridRow={5}>
        <div
          style={{ display: "flex", flexDirection: "column", rowGap: "10px" }}
        >
          {isPrimary ? annualPriceDescriptionList : monthlyPriceDescriptionList}
        </div>
      </GridItem>
      <GridItem gridColumn={column} gridRow={6} style={{ paddingTop: "10px" }}>
        {isDesktopActionsEnabled && (
          <PricingButton
            id={
              pricingList.pricingButton?.elementId
                ? generateId([pricingList.pricingButton?.elementId])
                : generateId([
                    pricingList.title,
                    "pricing",
                    "plan",
                    pricingList.pricingButton?.linkText,
                    "button",
                  ])
            }
            isPink={isPink}
            url={desktopUrl}
            linkText={pricingList.pricingButton?.linkText}
          />
        )}
        {!isDesktopActionsEnabled && (
          <PricingButton
            id={
              pricingList.pricingButton?.elementId
                ? generateId([pricingList.pricingButton?.elementId])
                : generateId([
                    pricingList.title,
                    "pricing",
                    "plan",
                    pricingList.pricingButton?.mobileLinkText,
                    "button",
                  ])
            }
            isPink={isPink}
            url={mobileUrl}
            linkText={pricingList.pricingButton?.mobileLinkText}
          />
        )}
      </GridItem>
      <GridItem
        gridColumn={column}
        gridRow={7}
        style={{
          padding: "10px 20px 35px 20px",
          borderRadius: "0 0 20px 20px",
          flexDirection: "column",
          rowGap: "10px",
        }}
      >
        {pricingList.listHeader && (
          <h3 style={{ fontSize: "15px", margin: "0px", lineHeight: "20px" }}>
            {pricingList.listHeader}
          </h3>
        )}
        <Container
          display="flex"
          flexDirection="column"
          style={{ rowGap: "17px" }}
        >
          {pricingList.featureList?.map(feature => {
            return (
              <Container display="flex" key={feature}>
                <Container display="flex" style={{ paddingRight: "10px" }}>
                  <Checkmark />
                </Container>
                <StyledP>{feature}</StyledP>
              </Container>
            );
          })}
        </Container>
      </GridItem>
    </>
  );
};

export { PricingList };
