import { colors } from "@util/constants";
import { Container } from "@util/standard";
import { SubscriptionTier } from "@util/types";
import React from "react";
import { FaCheck } from "react-icons/fa";
import styled, { CSSProperties } from "styled-components";
import { Button } from "./Button";
import { createButtonQueryParams } from "./utils/pricingUtils";

const overflowWidth = 950;
const CompareGrid = styled("div")<{ numCols: number }>`
  display: grid;
  grid-template-columns: repeat(${props => props.numCols}, 1fr);
  grid-template-rows: auto;
  grid-auto-rows: minmax(50px, auto);
  column-gap: 30px;
  width: 100%;
  @media only screen and (max-width: ${overflowWidth}px) {
    column-gap: 15px;
    grid-auto-rows: auto;
  }
`;

const Checkmark = styled(FaCheck)`
  width: 20px;
  height: 20px;
  color: ${colors.lightBlue};
`;

const GridCell = styled("div")<{
  isBlue?: boolean;
  isCenterContent?: boolean;
  fontWeight?: CSSProperties["fontWeight"];
  padding?: CSSProperties["padding"];
  tabletPadding?: CSSProperties["padding"];
}>`
  background-color: ${props => (props.isBlue ? colors.babyBlue : "white")};
  display: grid;
  text-align: ${props => (props.isCenterContent ? "center" : "left")};
  align-items: center;
  justify-content: ${props => (props.isCenterContent ? "center" : "start")};
  font-weight: ${props => props.fontWeight || "normal"};
  padding: ${props => props.padding || "0"};
  font-size: 17px;

  @media only screen and (max-width: ${overflowWidth}px) {
    padding: ${props => props.tabletPadding || "0"};
    font-size: 14px;
  }
`;

const StyledH4 = styled("h4")<{
  fontSize?: CSSProperties["fontSize"];
  tabletFontSize?: CSSProperties["fontSize"];
}>`
  font-size: ${props => props.fontSize || "17px"};
  padding: 0;
  margin: 0;

  @media only screen and (max-width: ${overflowWidth}px) {
    font-size: ${props => props.tabletFontSize || "0"};
  }
`;

const CappedCell = styled("div")<{ isBottom?: boolean }>`
  background-color: ${colors.babyBlue};
  display: grid;
  place-items: center;
  border-radius: ${props =>
    props.isBottom ? "0 0 20px 20px" : "20px 20px 0 0"};

  @media only screen and (max-width: ${overflowWidth}px) {
    min-height: 25px;
  }
`;

const HeaderCell = styled(CappedCell)`
  font-size: 22px;
  font-weight: bold;
  padding: 25px 25px 0 25px;
  text-align: center;

  @media only screen and (max-width: ${overflowWidth}px) {
    padding: 15px 10px 0 10px;
  }
`;

const ShrinkingButton = styled(Button)`
  height: 100%;
  &:hover {
    color: white;
    transform: scale(0.95);
  }

  transition: all 0.2s;
`;

const CompareGridHeader = ({
  numCols,
  plans,
}: {
  numCols: number;
  plans: string[];
}) => {
  const numOfBufferCells = numCols - plans.length;
  return (
    <>
      {Array.from({ length: numOfBufferCells }, (_, index) => (
        <GridCell key={index} />
      ))}
      {plans.map(plan => (
        <HeaderCell key={plan} isBottom={false}>
          <StyledH4 fontSize="22px" tabletFontSize="19px">
            {plan}
          </StyledH4>
        </HeaderCell>
      ))}
    </>
  );
};

const CompareGridFooter = ({
  numCols,
  numPlans,
}: {
  numCols: number;
  numPlans: number;
}) => {
  const numOfBufferCells = numCols - numPlans;
  return (
    <>
      {Array.from({ length: numOfBufferCells }, (_, index) => (
        <div key={index}></div>
      ))}
      {Array.from({ length: numPlans }, (_, index) => (
        <CappedCell key={index} isBottom />
      ))}
    </>
  );
};

const CompareGridSection = ({
  numPlans,
  section,
}: {
  numPlans: number;
  section: {
    title: string;
    features: { name: string; availability: (boolean | string)[] }[];
  };
}) => {
  return (
    <>
      <GridCell
        fontWeight={"bold"}
        padding="15px 0 0 0"
        tabletPadding="15px 0 0 0"
      >
        <StyledH4 tabletFontSize="14px">{section.title}</StyledH4>
      </GridCell>
      {Array.from({ length: numPlans }, (_, index) => (
        <GridCell isBlue key={index} />
      ))}
      {section.features.map(feature => (
        <React.Fragment key={feature.name}>
          <GridCell padding="5px 0" tabletPadding="5px 0">
            {feature.name}
          </GridCell>
          {feature.availability.map((description, index) => (
            <GridCell
              key={index}
              isCenterContent
              isBlue
              padding="0 10px"
              tabletPadding="0 10px"
            >
              {description === true ? <Checkmark /> : description}
            </GridCell>
          ))}
        </React.Fragment>
      ))}
    </>
  );
};

const CompareGridTrailingCta = ({
  numCols,
  plans,
  showPrimary,
}: {
  numCols: number;
  plans: Plan[];
  showPrimary: boolean;
}) => {
  const numOfBufferCells = numCols - plans.length;
  return (
    <>
      {Array.from({ length: numOfBufferCells }, (_, index) => (
        <GridCell key={index} />
      ))}
      {plans.map(plan => {
        return (
          <Container margin="30px 0 0 0" key={plan.name}>
            {plan.cta && (
              <ShrinkingButton
                isPink={plan.cta.isManualUpgrade}
                linkText={plan.cta.text}
                url={
                  !plan.cta.isManualUpgrade
                    ? `${plan.cta.url}?${createButtonQueryParams(
                        plan.subscriptionTier,
                        showPrimary ? "year" : "month"
                      )}`
                    : plan.cta.url
                }
              />
            )}
          </Container>
        );
      })}
    </>
  );
};

const ComparePlansGrid = ({
  isAddTrailingCta,
  isPrimary,
}: {
  isAddTrailingCta: boolean;
  isPrimary?: boolean;
}) => {
  const numPlans = data.plans.length;
  const numCols = numPlans + 1;
  const planNames = data.plans.map(plan => plan.name);
  return (
    <CompareGrid numCols={numCols}>
      <CompareGridHeader numCols={numCols} plans={planNames} />
      {data.sections.map(section => (
        <CompareGridSection
          section={section}
          numPlans={numPlans}
          key={section.title}
        />
      ))}
      <CompareGridFooter numCols={numCols} numPlans={numPlans} />
      {isAddTrailingCta && (
        <CompareGridTrailingCta
          numCols={numCols}
          plans={data.plans}
          showPrimary={isPrimary ?? false}
        />
      )}
    </CompareGrid>
  );
};

export interface CTA {
  url: string;
  text: string;
  isManualUpgrade: boolean;
}

export interface Plan {
  name: string;
  cta: CTA | null;
  subscriptionTier: SubscriptionTier;
}

export interface Feature {
  name: string;
  availability: (boolean | string)[];
}

export interface Section {
  title: string;
  features: Feature[];
}

export interface SubscriptionData {
  plans: Plan[];
  sections: Section[];
}

const data: SubscriptionData = {
  plans: [
    {
      name: "Free",
      cta: {
        url: "/signin",
        text: "Start for Free",
        isManualUpgrade: false,
      },
      subscriptionTier: SubscriptionTier.Free,
    },
    {
      name: "Growth",
      cta: {
        url: "/signin",
        text: "Start for Free",
        isManualUpgrade: false,
      },
      subscriptionTier: SubscriptionTier.Growth,
    },
    {
      name: "Business",
      cta: {
        url: "/signin",
        text: "Start for Free",
        isManualUpgrade: false,
      },
      subscriptionTier: SubscriptionTier.BusinessV2,
    },
    {
      name: "Business Pro",
      cta: {
        url: "/signin",
        text: "Start for Free",
        isManualUpgrade: false,
      },
      subscriptionTier: SubscriptionTier.BusinessPro,
    },
    {
      name: "Enterprise",
      cta: {
        url: "https://hello.celohealth.com/demo",
        text: "Contact Us",
        isManualUpgrade: true,
      },
      subscriptionTier: SubscriptionTier.Enterprise,
    },
  ],
  sections: [
    {
      title: "Messaging & Team Collaboration",
      features: [
        {
          name: "1-1 Conversations",
          availability: [true, true, true, true, true],
        },
        {
          name: "Group Conversations",
          availability: [true, true, true, true, true],
        },
        {
          name: "Patient Cases",
          availability: [true, true, true, true, true],
        },
        {
          name: "Read Receipts",
          availability: [true, true, true, true, true],
        },
        {
          name: "Reply to individual messages",
          availability: [true, true, true, true, true],
        },
        {
          name: "Forward Messages",
          availability: [true, true, true, true, true],
        },
        {
          name: "Delete Messages",
          availability: [true, true, true, true, true],
        },
        {
          name: "Mute Conversations",
          availability: [true, true, true, true, true],
        },
        {
          name: "Archive Conversations",
          availability: [true, true, true, true, true],
        },
        {
          name: "Pin Conversation",
          availability: [true, true, true, true, true],
        },
        {
          name: "Do-not-disturb",
          availability: [true, true, true, true, true],
        },
        {
          name: "Mentions",
          availability: [true, true, true, true, true],
        },
        {
          name: "Connections",
          availability: [true, true, true, true, true],
        },
        {
          name: "Secure Library",
          availability: [true, true, true, true, true],
        },
        {
          name: "Multimedia Sharing",
          availability: [true, true, true, true, true],
        },
        {
          name: "Document Sharing",
          availability: [true, true, true, true, true],
        },
        {
          name: "Celo Role Based Messaging",
          availability: [false, false, false, true, "Custom"],
        },
        {
          name: "External Chat",
          availability: [false, false, false, true, "Custom"],
        },
        {
          name: "Video and Audio Calling",
          availability: [false, false, false, true, "Custom"],
        },
        {
          name: "Celo Broadcast",
          availability: [false, false, false, false, "Custom"],
        },
      ],
    },
    {
      title: "Administration",
      features: [
        {
          name: "Workspaces",
          availability: ["1", "1", "1", "1", "Unlimited"],
        },
        {
          name: "Number of Workspace Users",
          availability: ["10", "20", "30+", "30+", "1000+"],
        },
        {
          name: "Access to Admin Portal",
          availability: [true, true, true, true, "Custom"],
        },
        {
          name: "Manage Users",
          availability: [true, true, true, true, true],
        },
        {
          name: "Manage Departments",
          availability: [false, true, true, true, true],
        },
        {
          name: "Manage Permissions",
          availability: [false, false, false, true, true],
        },
        {
          name: "Analytics Reports",
          availability: [false, false, false, false, true],
        },
        {
          name: "Partner Workspaces",
          availability: [false, false, false, false, true],
        },
      ],
    },
    {
      title: "Integration",
      features: [
        {
          name: "EHR Integration",
          availability: [false, false, false, false, true],
        },
        {
          name: "Active Directory",
          availability: [false, false, false, false, true],
        },
        {
          name: "SSO Support",
          availability: [false, false, false, false, true],
        },
        {
          name: "Custom Integrations",
          availability: [false, false, false, false, true],
        },
      ],
    },
    {
      title: "Security and Compliance",
      features: [
        {
          name: "HIPAA Compliant",
          availability: [true, true, true, true, true],
        },
        {
          name: "Data encryption at rest and in transit",
          availability: [true, true, true, true, true],
        },
        {
          name: "Biometric Authentication",
          availability: [true, true, true, true, true],
        },
        {
          name: "Identity Verification",
          availability: [true, true, true, true, true],
        },
        {
          name: "Consent Forms",
          availability: [true, true, true, true, true],
        },
        {
          name: "Business Associate Agreement (BAA)",
          availability: [
            "Limited to a BAA with individual users only and not with the organization.",
            true,
            true,
            true,
            true,
          ],
        },
        {
          name: "Verify Workspace",
          availability: [false, true, true, true, true],
        },
        {
          name: "Data Residency",
          availability: [false, false, false, false, true],
        },
        {
          name: "Custom Consent",
          availability: [false, false, false, false, true],
        },
      ],
    },
    {
      title: "Support",
      features: [
        {
          name: "Access to Celo Onboarding Experts",
          availability: [false, false, true, true, true],
        },
        {
          name: "Level of Support",
          availability: [
            "Help Center",
            "Standard Support",
            "Priority Support",
            "Priority Support",
            "Enterprise SLA",
          ],
        },
      ],
    },
  ],
};

export default ComparePlansGrid;
