import ComparePlansGrid from "@components-v3/ComparePlansGrid";
import { PlansSection } from "@components-v3/Sections/PlansSection";
import LayOut from "@components/layout";
import Cta from "@components/shared/cta";
import SEO from "@components/shared/seo";
import { Query, SanityPlansPageConnection } from "@graphql-types";
import { TABLET_LANDSCAPE } from "@util/constants";
import { useRegionChanged, useSafeMediaQuery } from "@util/hooks";
import { Container } from "@util/standard";
import { graphql, PageProps } from "gatsby";
import React, { useState } from "react";

interface Data extends Query {
  altRegions: SanityPlansPageConnection;
}

interface PlansPageProps {
  iban: string;
  slug: string;
}

interface PlansPageProps extends PageProps {
  data: Data;
  pageContext: PlansPageProps;
}

const PlansPage = (props: PlansPageProps) => {
  const [showPrimary, setShowPrimary] = useState(true);
  const sanityPlansPage = props.data.allSanityPlansPage.edges[0].node;
  const alternateRegions = props.data.altRegions.nodes.map(
    alt => alt._rawRegion
  );

  useRegionChanged("plans");

  const { plansSection, seo, demoCtaSection } = sanityPlansPage;
  const isTablet = useSafeMediaQuery({ minWidth: TABLET_LANDSCAPE });

  return (
    <>
      <SEO
        seoData={seo}
        alternateRegions={alternateRegions}
        slug={props.pageContext.slug}
      />
      <LayOut>
        <Container
          style={{
            placeItems: "center",
          }}
          display="flex"
          flexDirection="column"
          margin="auto"
          padding="50px 50px"
          mobilePadding="10px"
          tabletMargin="0 50px"
          mobileMargin="0 0"
        >
          <PlansSection
            plansSectionData={plansSection}
            isPrimary={showPrimary}
            handleToggle={() => setShowPrimary(prevState => !prevState)}
          />
          {isTablet && (
            <Container
              display="flex"
              flexDirection="column"
              style={{
                placeItems: "center",
              }}
              margin="auto"
              padding="100px 0 50px 0"
            >
              {/* This is hard coded because the entire compare grid is static anyway */}
              <h2
                style={{
                  textAlign: "center",
                  marginBottom: "40px",
                  fontSize: "35px",
                }}
              >
                Discover how features differ between plans.
              </h2>
              <ComparePlansGrid isAddTrailingCta isPrimary={showPrimary} />
            </Container>
          )}
        </Container>
        <Container backgroundColor="babyBlue" paddingVertical={5}>
          <Cta content={demoCtaSection} noDownloadButtons showButtonInMobile />
        </Container>
      </LayOut>
    </>
  );
};

export const query = graphql`
  query PlansQuery($iban: String) {
    allSanityPlansPage(filter: { region: { iban: { eq: $iban } } }) {
      edges {
        node {
          id
          plansSection {
            title
            description
            _rawPricingLists(resolveReferences: { maxDepth: 10 })
            toggleSwitch {
              primaryLabel
              secondaryLabel
            }
            currency
          }
          demoCtaSection {
            ctaButton {
              internallink
              linkDescription
              linkStyle
              linktext
              url
              newwindow
              navigateBack
            }
            ctaImage {
              ...sanityImageWithMeta
            }
            ctaText {
              _rawContent(resolveReferences: { maxDepth: 10 })
            }
          }
          seo {
            ...sanitySeo
          }
        }
      }
    }
    altRegions: allSanityPlansPage {
      nodes {
        _rawRegion(resolveReferences: { maxDepth: 10 })
      }
    }
  }
`;
export default PlansPage;
export type { PlansPageProps };
