// Helper to generate an encoded url for plans ctas
export const createButtonQueryParams = (
  productType: string,
  billingInterval: "year" | "month"
) => {
  const params = new URLSearchParams();
  params.set("product_trial_type", productType);
  params.set("billing_interval", billingInterval);
  return params.toString();
};
